import React from 'react';

const Footer = () => {
  return (
    <>
        <p>ⓒ listt.io</p>
        <p>2021</p>
  
    </>
  );
};

export default Footer;